export const accounts = [
  // {
  //   key: 'mltech',
  //   name: 'ML TECH',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech_executor',
  //   executorRest: 'https://executor-mltech-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  //   dropletID: 313350283,
  // },
  // {
  //   key: 'mltech4',
  //   name: 'ML TECH 4',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech4_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech4_executor',
  //   executorRest: 'http://localhost:7002',
  //   server: 2,
  // },
  {
    key: 'mltech5',
    name: 'Allo1',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech5_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech5_executor',
    executorRest: 'https://executor-mltech5-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 324263530,
  },
  // {
  //   key: 'mltech6',
  //   name: 'ML TECH 6',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech6_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech6_executor',
  //   executorRest: 'https://executor-mltech6-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  // },
  {
    key: 'mltech7',
    name: 'Allo2',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech7_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech7_executor',
    executorRest: 'https://executor-mltech7-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 355775373,
  },
  {
    key: 'mltech3',
    name: 'Allo3',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech3_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech3_executor',
    executorRest: 'https://executor-mltech3-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 442954673,
  },
  {
    key: 'mltech2',
    name: 'Allo4',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech2_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech2_executor',
    executorRest: 'https://executor-mltech2-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 449561311,
  },
  {
    key: 'mltech4',
    name: 'Allo5',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech4_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech4_executor',
    executorRest: 'https://executor-mltech4-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 449562446,
  },
  {
    key: 'mltech6',
    name: 'Allo6',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech6_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech6_executor',
    executorRest: 'https://executor-mltech6-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 449567751,
  },
  {
    key: 'mltech',
    name: 'Allo7',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech_executor',
    executorRest: 'https://executor-mltech-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 449761461,
  },
  {
    key: 'mariospas',
    name: 'Marios Passaris',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mariospas_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mariospas_executor',
    executorRest: 'https://executor-mariospas-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 319759105,
  },
  // {
  //   key: 'johnito',
  //   name: 'Johnito',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/johnito_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/johnito_executor',
  //   executorRest: 'https://executor-johnito-rest.digitalocean-binance.orange-quest.com,
  //   server: 2,
  // },
  // {
  //   key: 'dimi',
  //   name: 'Dimi',
  //   brain: 'https://grpc-web.orange-quest.com/dimi_brain',
  //   executor: 'https://grpc-web.orange-quest.com/dimi_executor',
  //   executorRest: 'https://executor-dimi-rest.digitalocean-binance.orange-quest.com,
  //   server: 1,
  // },
  {
    key: 'konstantinos',
    name: 'Konstantinos',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/konstantinos_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/konstantinos_executor',
    executorRest: 'https://executor-konstantinos-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 319793503,
  },
  // {
  //   key: 'labropoulos',
  //   name: 'Labropoulos',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/labropoulos_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/labropoulos_executor',
  //   executorRest: 'https://executor-labropoulos-rest.digitalocean-binance.orange-quest.com,
  //   server: 2,
  // },
  {
    key: 'claire',
    name: 'Claire',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/claire_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/claire_executor',
    executorRest: 'https://executor-claire-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 319792287,
  },
  // {
  //   key: 'panos',
  //   name: 'Monedas',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/panos_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/panos_executor',
  //   executorRest: 'https://executor-panos-rest.digitalocean-binance.orange-quest.com,
  //   server: 2,
  // },
  // {
  //   key: 'weddad',
  //   name: 'Weddad',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/weddad_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/weddad_executor',
  //   executorRest: 'https://executor-weddad-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  //   dropletID: 329861903,
  // },
  // {
  //   //stathos but dimi now
  //   key: 'dimi',
  //   name: 'Dimi',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/stathos_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/stathos_executor',
  //   executorRest: 'https://executor-stathos-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  // },
  {
    key: 'alextest',
    name: 'Alex Test',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/alextest_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/alextest_executor',
    executorRest: 'https://executor-alextest-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 312819576,
  },
];

export const accountsLocal = [
  {
    key: 'local',
    name: 'Local',
    brain: 'http://grpc-web.localhost/local_brain',
    executor: 'http://grpc-web.localhost/local_executor',
    executorRest: 'http://localhost:7002',
    server: 2,
    dropletID: 1,
  },
  {
    key: 'alextest',
    name: 'Alex Test',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/alextest_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/alextest_executor',
    executorRest: 'https://executor-alextest-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 312819576,
  },
  // {
  //   key: 'mltech3',
  //   name: 'ML TECH 3',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech3_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech3_executor',
  //   executorRest: 'https://executor-mltech3-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  // },
  // {
  //   key: 'mltech4',
  //   name: 'ML TECH 4',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech4_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech4_executor',
  //   executorRest: 'http://localhost:7002',
  //   server: 2,
  // },
  // {
  //   key: 'mltech5',
  //   name: 'ML TECH 5',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech5_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech5_executor',
  //   executorRest: 'https://executor-mltech5-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  // },
  // {
  //   key: 'mltech6',
  //   name: 'ML TECH 6',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech6_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech6_executor',
  //   executorRest: 'https://executor-mltech6-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  // },
  // {
  //   key: 'mltech7',
  //   name: 'ML TECH 7',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech7_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mltech7_executor',
  //   executorRest: 'https://executor-mltech7-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  // },
  // {
  //   key: 'mariospas',
  //   name: 'Marios Passaris',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/mariospas_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/mariospas_executor',
  //   executorRest: 'https://executor-mariospas-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  // },
  // {
  //   key: 'konstantinos',
  //   name: 'Konstantinos',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/konstantinos_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/konstantinos_executor',
  //   executorRest: 'https://executor-konstantinos-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  // },
  {
    key: 'claire',
    name: 'Claire',
    brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/claire_brain',
    executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/claire_executor',
    executorRest: 'https://executor-claire-rest.digitalocean-binance.orange-quest.com',
    server: 2,
    dropletID: 319792287,
  },
  // {
  //   key: 'weddad',
  //   name: 'Weddad',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/weddad_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/weddad_executor',
  //   executorRest: 'https://executor-weddad-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  // },
  // {
  //   key: 'alextest',
  //   name: 'Alex Test',
  //   brain: 'https://grpc-web.digitalocean-binance.orange-quest.com/alextest_brain',
  //   executor: 'https://grpc-web.digitalocean-binance.orange-quest.com/alextest_executor',
  //   executorRest: 'https://executor-alextest-rest.digitalocean-binance.orange-quest.com',
  //   server: 2,
  // },
];
